import React from "react";
import {createTheme, ThemeProvider} from "@material-ui/core";
import TrackingScripts from "./src/components/tracking/TrackingScripts";

const theme = createTheme({
    palette: {
        primary: {
            light: '#ed655a',
            main: '#ed655a',
            dark: '#ed655a',
            contrastText: '#fff',
        },
    },
});

export const wrapRootElement = ({element}) => {

    return (
        <ThemeProvider theme={theme}>
            <TrackingScripts allowed={true}/>
            {element}
        </ThemeProvider>
    );
}
