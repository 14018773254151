import React from 'react';
import {Helmet} from "react-helmet";

const TrackingScripts = ({allowed}) => {
    return allowed ? (
        <Helmet>
            <script src="https://www.googletagmanager.com/gtag/js?id=G-T2N9SDELBZ" async/>
            <script type="text/javascript" src="/tracking/gtag.js" async/>
            {/*<script type="text/javascript" src="/tracking/gr.js" async/>*/}
        </Helmet>
    ) : null;
};

export default TrackingScripts;